// extracted by mini-css-extract-plugin
export var autoPromo = "howInstallmentWorks-module--autoPromo--84-qR";
export var blackLink = "howInstallmentWorks-module--blackLink--Qhnga";
export var bonus1500 = "howInstallmentWorks-module--bonus1500--KROYj";
export var brDesk = "howInstallmentWorks-module--brDesk--zH0su";
export var brDeskLarge = "howInstallmentWorks-module--brDeskLarge--SiyeY";
export var brDesktop = "howInstallmentWorks-module--brDesktop--S9Jc5";
export var brLarge = "howInstallmentWorks-module--brLarge--ZNmuy";
export var brLenta = "howInstallmentWorks-module--brLenta--SQ3e9";
export var brMob = "howInstallmentWorks-module--brMob--Ma4Q6";
export var brMobDesk = "howInstallmentWorks-module--brMobDesk--FAljS";
export var brMobLarge = "howInstallmentWorks-module--brMobLarge--9gNIR";
export var brMobTab = "howInstallmentWorks-module--brMobTab--0mf3s";
export var brMobTabDesk = "howInstallmentWorks-module--brMobTabDesk--2uMu0";
export var brMobile = "howInstallmentWorks-module--brMobile--XKjF9";
export var brNoDesk = "howInstallmentWorks-module--brNoDesk--SYhKj";
export var brNoTab = "howInstallmentWorks-module--brNoTab--567y2";
export var brTab = "howInstallmentWorks-module--brTab--KZ6Tn";
export var brTabDesk = "howInstallmentWorks-module--brTabDesk--xtDdZ";
export var brTabDeskLarge = "howInstallmentWorks-module--brTabDeskLarge--X1JpX";
export var brTabLarge = "howInstallmentWorks-module--brTabLarge--wgI3C";
export var btsAuto = "howInstallmentWorks-module--btsAuto--OOUVE";
export var cashback100 = "howInstallmentWorks-module--cashback100--RzqFR";
export var close = "howInstallmentWorks-module--close--kaNDr";
export var container = "howInstallmentWorks-module--container--Z-6-P";
export var genderAction = "howInstallmentWorks-module--genderAction--7Nw6P";
export var halva = "howInstallmentWorks-module--halva--y2Ws4";
export var hintModal = "howInstallmentWorks-module--hintModal--6Iada";
export var hippo = "howInstallmentWorks-module--hippo--GuffT";
export var inviteFriend = "howInstallmentWorks-module--inviteFriend--XsCgA";
export var kiberponedelnik = "howInstallmentWorks-module--kiberponedelnik--3Sd3r";
export var lenta = "howInstallmentWorks-module--lenta--566yW";
export var link = "howInstallmentWorks-module--link--pXShy";
export var nedeliUmnuxRassrochek = "howInstallmentWorks-module--nedeliUmnuxRassrochek--ifwZm";
export var newYear = "howInstallmentWorks-module--newYear--9ej3+";
export var noBreakDisplayLD = "howInstallmentWorks-module--noBreakDisplayLD--3Z4Uy";
export var noMobile = "howInstallmentWorks-module--noMobile--8ik32";
export var pkw = "howInstallmentWorks-module--pkw---OglR";
export var pkwAviasales = "howInstallmentWorks-module--pkwAviasales--6nx61";
export var pkwOther = "howInstallmentWorks-module--pkwOther--i9hYS";
export var pkwOzon = "howInstallmentWorks-module--pkwOzon--IvePH";
export var pkwRedesign = "howInstallmentWorks-module--pkwRedesign--xA5fA";
export var pointer = "howInstallmentWorks-module--pointer--Q1iwt";
export var productsCategories = "howInstallmentWorks-module--productsCategories--TcSZf";
export var promopage = "howInstallmentWorks-module--promopage--lKwzK";
export var redisignBezProcentov = "howInstallmentWorks-module--redisignBezProcentov--M+0km";
export var refin = "howInstallmentWorks-module--refin---C+s9";
export var refinKreditnoyKarty = "howInstallmentWorks-module--refinKreditnoyKarty--hMzeC";
export var section = "howInstallmentWorks-module--section--jA5PJ";
export var shapka = "howInstallmentWorks-module--shapka--xAG0l";
export var shuba = "howInstallmentWorks-module--shuba--tK2Zs";
export var standard = "howInstallmentWorks-module--standard--fP5h6";
export var step = "howInstallmentWorks-module--step--8WjEi";
export var stepSubtitle = "howInstallmentWorks-module--stepSubtitle--B+JNJ";
export var stepText = "howInstallmentWorks-module--stepText--3SdE8";
export var stepTitle = "howInstallmentWorks-module--stepTitle--kdxW5";
export var steps = "howInstallmentWorks-module--steps--cV6L8";
export var stickerPay = "howInstallmentWorks-module--stickerPay--SAVAj";
export var title = "howInstallmentWorks-module--title--pDMpK";
export var toolTipDashed = "howInstallmentWorks-module--toolTipDashed--m4lVt";
export var tooltip = "howInstallmentWorks-module--tooltip--GjMLc";
export var vkEducation = "howInstallmentWorks-module--vkEducation--K6Sr2";
export var wrapper = "howInstallmentWorks-module--wrapper--Qv1lh";
export var zoon = "howInstallmentWorks-module--zoon--R-FIP";